import { Injectable } from '@angular/core';
import { commonConstants, SUCCESS_MESSAGE_CONSTANTS } from '../../CONSTANTS/tpi-common-constants';
import { BULK_EDIT, BULK_ERROR_MESSAGE_GROUP } from '../../CONSTANTS/tpi-error-tags';
import { BulkGridSantized, BulkOptionMessages, BulkOptionRequest, MortgageBulkEntries, MortgageBulkInformationRequest, MortgageBulkInformationResponse, MortgageCompanyBulkRequest, MortgageInformationResponse } from '../../tpi-models/general/mortgage-company';
import { SessionService } from '../tpi-session/session.service';
import { UtilsService } from '../utility/utils-service.service';
import { LoggerService } from '../logger/logger.service';

@Injectable({
    providedIn: 'root'
})
export class MortgageBulkUploadAdapterService {
    constructor(private sessionService: SessionService, private utils: UtilsService, private loggerService: LoggerService) { }

    checkFailedResponseAgainstForm(responseFailed: {
        errorsFound: Array<string>;
    }): string {
        let errorMessageBulkEdit = '';
        if (responseFailed.errorsFound.length > 0) {
            if (responseFailed.errorsFound.includes('DB_VALIDATION_FAILED')) {
                errorMessageBulkEdit = `${BULK_EDIT.ERROR_MESSAGE.SYSTEM_DB_ERROR}<br>`;
            } else if (responseFailed.errorsFound.includes('FRONTEND_ERRORS')) {
                errorMessageBulkEdit = BULK_ERROR_MESSAGE_GROUP.getCommonErrorList(responseFailed.errorsFound.includes('DUPLICATE'));
            } else if (responseFailed.errorsFound.includes('ZIPCODE_VALIDATION_FAILED') ||
                responseFailed.errorsFound.includes('POLICYNUMBER_VALIDATION_FAILED') ||
                responseFailed.errorsFound.includes('LOANNUMBER_VALIDATION_FAILED')) {
                errorMessageBulkEdit = `${BULK_EDIT.ERROR_MESSAGE.PROPERTY_MAIL_NOT_MATCH}<br>`;
            } else if (responseFailed.errorsFound.includes('MORTGAGE_COMPANY_FAILED')) {
                errorMessageBulkEdit = `${BULK_EDIT.ERROR_MESSAGE.UNABLE_TO_PROCESS}<br>`;
            }
        }
        return errorMessageBulkEdit;
    }

    checkUpdateMortgageResponse(response: MortgageBulkInformationResponse, messages: BulkOptionMessages): BulkOptionMessages {
        const failedStatusCode = '400';
        if (response?.errorMessage?.toLowerCase().includes('mortgage') && response?.status?.toString() === failedStatusCode) {
            const errorsFound = ['MORTGAGE_COMPANY_FAILED'];
            messages = {
                errorMessage: this.checkFailedResponseAgainstForm({ errorsFound }),
                successMessage: '',
                successfulPolicies: 0,
                failedPolicies: this.convertToBulkFormEntries(response.failed),
                mortgageUpdateFailed: true
            };
        }
        return messages;
    }

    convertToBulkFormEntries(bulkEntriesResponse: Array<MortgageBulkEntries>): Array<Array<string>> {
        const entries = [];
        for (const bulkEntry of bulkEntriesResponse) {
            entries.push([bulkEntry.loanNumber, bulkEntry.policyNumber, bulkEntry.policyZipCode]);
        }
        return entries;
    }

    convertToBulkRequestEntries(bulkRequestGridForm: Array<Array<string>>): Array<MortgageBulkEntries> {
        const entries = [];
        for (const bulkRequestGridValue of bulkRequestGridForm) {
            entries.push({
                loanNumber: bulkRequestGridValue[0],
                policyNumber: bulkRequestGridValue[1],
                policyZipCode: bulkRequestGridValue[2]
            });
        }
        return entries;
    }

    createBulkRequest(mortgageCompany: MortgageCompanyBulkRequest, bulkRequestGridForm: Array<Array<string>>): MortgageBulkInformationRequest {
        const session = JSON.parse(sessionStorage.getItem('session'));
        const email = session.context.session.account.email;
        return {
            userId: email,
            effectiveDate: this.utils.currentDateValue(),
            timestamp: new Date().toISOString(),
            entries: this.convertToBulkRequestEntries(bulkRequestGridForm),
            mortgageCompany
        };
    }

    createMortgageCompanyRequest(companyIdToMap: string, companyMap: any): MortgageInformationResponse {
        let mortgageCompany: MortgageInformationResponse = null;
        for (const [key, value] of companyMap.entries()) {
            if (key === companyIdToMap) {
                const { companyId, name, ediNumber, address } = value;
                const { addressId, addressLine1, addressLine2, city, state, postalCode, country } = address;
                mortgageCompany = {
                    address: {
                        addressId,
                        addressLine1,
                        addressLine2: addressLine2 || '',
                        city,
                        state,
                        postalCode,
                        country: country || commonConstants.API_CONSTANT.RESPONSE_MAPPING.US
                    },
                    companyId,
                    companyName: name,
                    ediNumber: ediNumber || commonConstants.API_CONSTANT.RESPONSE_MAPPING.NOT_AVAILABLE
                };
            }
        }
        return mortgageCompany;
    }

    hasFrontEndAndBackEndErrors(response: MortgageBulkInformationResponse, messages: BulkOptionMessages, options: BulkOptionRequest): BulkOptionMessages {
        if (response.failed.length > 0 && options.bulkGridSanitizedValues.invalidGrid.length > 0) {
            // havingFailedResponseAndHavingInvalidGrid
            const combinedArray = this.convertToBulkFormEntries(response.failed).concat(options.bulkGridSanitizedValues.invalidGrid);
            let errorsFound = this.retrieveFailureReasons(response.failed);
            errorsFound.push('FRONTEND_ERRORS');
            errorsFound = this.pushDuplicates(errorsFound, options.bulkGridSanitizedValues);
            messages = this.setSuccessMessageAndCount(messages, options.totalPolicies, response.failed.length + options.bulkGridSanitizedValues.invalidGrid.length);
            messages.failedPolicies = combinedArray;
            messages.errorMessage = this.checkFailedResponseAgainstForm({
                errorsFound
            });
        }
        return messages;
    }

    pushDuplicates(errorsFound: Array<string>, bulkGridSanitizedValues: BulkGridSantized): Array<string> {
        if (bulkGridSanitizedValues.duplicates.length > 0) {
            errorsFound.push('DUPLICATE');
        }
        return errorsFound;
    }

    retrieveFailureReasons(failedResponse: Array<MortgageBulkEntries>): Array<string> {
        const pullErrors = [];
        for (const response of failedResponse) {
            pullErrors.push(response.failedReason);
        }
        return pullErrors;
    }

    setErrorOrSuccessMessage(response: MortgageBulkInformationResponse, options: BulkOptionRequest): BulkOptionMessages {
        let messages: BulkOptionMessages = {
            successMessage: '',
            errorMessage: '',
            successfulPolicies: 0
        };
        messages = this.checkUpdateMortgageResponse(response, messages);
        if (messages.mortgageUpdateFailed) {
            this.loggerService.logCurrentContents();
            return messages;
        }
        if (response.failed.length === 0 && options.bulkGridSanitizedValues.invalidGrid.length === 0) {
            messages.successfulPolicies = options.bulkGridSanitizedValues.validGrid.length;
            messages.successMessage = SUCCESS_MESSAGE_CONSTANTS.bulkSubmitSuccess;
        } else if (response.failed.length === 0 && options.bulkGridSanitizedValues.invalidGrid.length > 0) {
            // noFailedResponseAndHavingInvalidGrid
            let errorsFound = ['FRONTEND_ERRORS'];
            errorsFound = this.pushDuplicates(errorsFound, options.bulkGridSanitizedValues);
            messages = this.setSuccessMessageAndCount(messages, options.totalPolicies, options.bulkGridSanitizedValues.invalidGrid.length);
            messages.failedPolicies = options.bulkGridSanitizedValues.invalidGrid;
            messages.errorMessage = this.checkFailedResponseAgainstForm({ errorsFound });
        } else if (response.failed.length > 0 && options.bulkGridSanitizedValues.invalidGrid.length === 0) {
            // havingFailedResponseAndNotHavingInvalidGrid
            messages = this.setSuccessMessageAndCount(messages, options.totalPolicies, response.failed.length);
            messages.failedPolicies = this.convertToBulkFormEntries(response.failed);
            messages.errorMessage = this.checkFailedResponseAgainstForm({
                errorsFound: this.retrieveFailureReasons(response.failed)
            });
        }
        messages = this.hasFrontEndAndBackEndErrors(response, messages, options);
        this.loggerService.logCurrentContents();
        return messages;
    }

    setSuccessMessageAndCount(messages: BulkOptionMessages, totalPolicies: number, invalidPolicy: number): BulkOptionMessages {
        messages.successfulPolicies = totalPolicies - invalidPolicy;
        messages.successMessage = messages.successfulPolicies > 0 ? SUCCESS_MESSAGE_CONSTANTS.bulkSubmitSuccess : null;
        return messages;
    }
}
